<template>
  <div>
    <div v-if="!loaded" class="empty_map" style="text-align:center;">
      <v-progress-circular :indeterminate="true" size="50" style="margin-top:125px;"></v-progress-circular>
    </div>
    <div v-if="loaded">
      <GmapMap
        v-if="points.length !== 0"
        :center="map_center"
        :zoom="map_zoom"
        map-type-id="roadmap"
        style="width: calc(100% + 2 * 12px); height: 400px;margin:-12px;"
      >
        <GmapPolyline :path.sync="points" v-bind:options="{ strokeColor:'#000'}"></GmapPolyline>
      </GmapMap>
      <div class="empty_map" v-else></div>
      <div style="margin-top:30px;">
        <v-layout style="margin:0 0 10px 0;">
          <v-flex xs6>
            <h2>{{ name }}</h2>
          </v-flex>
          <v-flex xs6 style="text-align:right;font-size:20px;">
            <i class="fad fa-cloud-download" style="cursor:pointer;margin-right:20px;" @click="download"></i>
            <i class="fad fa-pencil" style="cursor:pointer;" @click="d_edit = true"></i>
          </v-flex>
        </v-layout>
        <v-layout row wrap style="margin: 0 0 30px 0;">
          <v-flex xs12 sm12 md6 lg6 style="padding:10px;">
            <v-card>
              <v-card-title>{{ convertDateTime(start_time) }}</v-card-title>
              <v-card-subtitle>{{ $t("tracks.time") }}</v-card-subtitle>
            </v-card>
          </v-flex>
          <v-flex xs6 sm6 md6 lg3 style="padding:10px;">
            <v-card>
              <v-card-title>
                <span v-html="getTrackTypeFormatted(track_type)"></span>
              </v-card-title>
              <v-card-subtitle>{{ $t("tracks.type") }}</v-card-subtitle>
            </v-card>
          </v-flex>
          <v-flex xs6 sm6 md4 lg3 style="padding:10px;">
            <v-card>
              <v-card-title>
                <i style="margin-right:5px;" class="fad fa-road"></i>
                {{ distance }}
              </v-card-title>
              <v-card-subtitle>{{ $t("tracks.distance") }}</v-card-subtitle>
            </v-card>
          </v-flex>
          <v-flex xs6 sm6 md4 lg3 style="padding:10px;" v-if="duration !== null">
            <v-card>
              <v-card-title>
                <i style="margin-right:5px;" class="fad fa-clock"></i>
                {{ moment("2015-01-01").startOf('day')
                .seconds(duration)
                .format('HH:mm:ss') }}
              </v-card-title>
              <v-card-subtitle>{{ $t("tracks.duration") }}</v-card-subtitle>
            </v-card>
          </v-flex>
          <v-flex xs6 sm6 md4 lg3 style="padding:10px;" v-if="heartRateAvg !== 0">
            <v-card>
              <v-card-title>
                <i style="margin-right:5px;" class="fad fa-heartbeat"></i>
                {{ Math.round(heartRateAvg) }} bpm
              </v-card-title>
              <v-card-subtitle>{{ $t("tracks.heartRateAvg") }}</v-card-subtitle>
            </v-card>
          </v-flex>
          <v-flex xs6 sm6 md4 lg3 style="padding:10px;" v-if="uphillCalc !== 0">
            <v-card>
              <v-card-title>
                <i style="margin-right:5px;" class="fad fa-long-arrow-alt-up fa-rotate-45"></i>
                {{ Math.round(uphillCalc) }} m
              </v-card-title>
              <v-card-subtitle>{{ $t("tracks.uphill") }}</v-card-subtitle>
            </v-card>
          </v-flex>
          <v-flex xs6 sm6 md4 lg3 style="padding:10px;" v-if="uphillCalc !== 0">
            <v-card>
              <v-card-title>
                <i style="margin-right:5px;" class="fad fa-long-arrow-alt-right fa-rotate-45"></i>
                {{ Math.round(downhillCalc) }} m
              </v-card-title>
              <v-card-subtitle>{{ $t("tracks.downhill") }}</v-card-subtitle>
            </v-card>
          </v-flex>
        </v-layout>
        <apexchart
          v-if="elevations.length > 0"
          height="300"
          type="line"
          :options="chart_options_elevation"
          :series="chart_elevation"
        ></apexchart>
        <apexchart
          v-if="heartRate.length > 0"
          height="300"
          type="line"
          :options="chart_options_heartRate"
          :series="chart_heartRate"
        ></apexchart>
      </div>
    </div>
    <v-dialog max-width="400" v-model="d_edit">
      <v-form @submit="editTrack">
        <v-card>
          <v-card-title>{{ $t("tracks.edit") }}</v-card-title>
          <v-card-text>
            <v-text-field :label="$t('tracks.name')" v-model="edit_name"></v-text-field>
            <v-select :label="$t('tracks.type')" v-model="edit_track_type" :items="track_types">
              <template v-slot:item="{item}">
                <i class="fad" :class="item.icon" style="margin-right:5px;"></i>
                <span>{{ $t(item.name) }}</span>
              </template>
              <template v-slot:selection="{item}">
                <i class="fad" :class="item.icon" style="margin-right:5px;"></i>
                <span>{{ $t(item.name) }}</span>
              </template>
            </v-select>
            <v-dialog
              ref="d_edit_date"
              v-model="d_edit_date"
              :return-value.sync="edit_date"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="edit_date"
                  :label="$t('tracks.date')"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="edit_date" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="d_edit_date = false">Cancel</v-btn>
                <v-btn text color="primary" @click="$refs.d_edit_date.save(edit_date)">OK</v-btn>
              </v-date-picker>
            </v-dialog>
            <v-dialog
              ref="d_edit_time"
              v-model="d_edit_time"
              :return-value.sync="edit_time"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="edit_time"
                  :label="$t('tracks.time')"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker format="24hr" v-model="edit_time" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="d_edit_time = false">Cancel</v-btn>
                <v-btn text color="primary" @click="$refs.d_edit_time.save(edit_time)">OK</v-btn>
              </v-time-picker>
            </v-dialog>
          </v-card-text>
          <v-card-actions>
            <v-btn text color="error" @click="deleteTrack">{{ $t("general.delete") }}</v-btn>
            <v-spacer></v-spacer>
            <v-btn text type="submit" color="primary">{{ $t("general.save") }}</v-btn>
            <v-btn text color="default" @click="d_edit = false">{{ $t("general.close") }}</v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { storage } from "../schmucklicloud";

import gpxParser from "../plugins/gpx-parser";
import { parseTCXString } from "../plugins/tcx-parser";
import { Notifications } from "../notification";
import { GOOGLE_MAPS_KEY } from "../keys";
import track_types from "../assets/data/types";

export default {
  data() {
    return {
      id: 0,
      loaded: false,
      type: null,
      raw: null,
      name: "",
      start_time: null,
      duration: null,
      track: null,
      track_type: null,
      points: [],
      elevations: [],
      heartRate: [],
      speeds: [],

      d_edit: false,
      d_edit_date: false,
      d_edit_time: false,
      edit_name: "",
      edit_track_type: null,
      edit_date: null,
      edit_time: null,

      track_types: track_types.types
    };
  },
  mounted() {
    this.id = this.$route.params.id;
    this.load(this.id);
  },
  computed: {
    dataURI() {
      switch(this.type) {
        case "tcx":
          return 'data:application/tcx+xml;base64,' + window.btoa(this.raw);
        case "gpx":
        default:
          return 'data:application/gpx+xml;base64,' + window.btoa(this.raw);
      }
    },
    distance() {
      var distance;
      switch (this.type) {
        case "tcx":
          distance = this.track.distance;
          break;
        case "gpx":
          distance = this.track.distance.total;
          break;
        default:
          return "...";
      }
      if (distance >= 1000) {
        return Math.round((distance / 1000) * 10) / 10 + " km";
      } else {
        return Math.round(distance * 10) / 10 + " m";
      }
    },
    heartRateAvg() {
      const sum = this.heartRate.reduce((a, b) => a + b, 0);
      return sum / this.heartRate.length || 0;
    },
    uphillCalc() {
      var last = 0;
      var total = 0;
      this.elevations.forEach(
        function(elevation, index) {
          if (index === 0) {
            last = elevation;
            return;
          }
          if (last < elevation) {
            total += elevation - last;
          }
          last = elevation;
        }.bind(this)
      );
      return total;
    },
    downhillCalc() {
      var last = 0;
      var total = 0;
      this.elevations.forEach(
        function(elevation, index) {
          if (index === 0) {
            last = elevation;
            return;
          }
          if (last > elevation) {
            total += last - elevation;
          }
          last = elevation;
        }.bind(this)
      );
      return total;
    },
    chart_elevation() {
      return [
        {
          name: this.$t("tracks.meters_above_sea"),
          data: this.elevations
        }
      ];
    },
    chart_heartRate() {
      return [
        {
          name: this.$t("tracks.bpm"),
          data: this.heartRate
        }
      ];
    },
    chart_options_elevation() {
      return {
        chart: {
          id: "chart_elevation",
          height: 350,
          type: "line",
          group: "track",
          zoom: {
            enabled: false
          }
        },
        theme: {
          mode: this.$vuetify.theme.dark ? "dark" : "light"
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "smooth"
        },
        colors: ["#FFB024"],
        title: {
          text: this.$t("tracks.elevation"),
          align: "left"
        },
        xaxis: {
          labels: {
            formatter: function() {
              return "";
            }
          }
        }
      };
    },
    chart_options_heartRate() {
      return {
        chart: {
          id: "chart_heartrate",
          height: 350,
          type: "line",
          group: "track",
          zoom: {
            enabled: false
          }
        },
        theme: {
          mode: this.$vuetify.theme.dark ? "dark" : "light"
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: "smooth"
        },
        colors: ["#ff0000"],
        title: {
          text: this.$t("tracks.heartRate"),
          align: "left"
        },
        xaxis: {
          labels: {
            formatter: function() {
              return "";
            }
          }
        }
      };
    },
    map_zoom() {
      return this.getZoomLevel(this.points);
    },
    map_center() {
      return this.getCenterFromCoordinates(this.points);
    }
  },
  methods: {
    async load(id) {
      var response = await storage.getById("tracks", id);
      if (response.isOK) {
        this.type = response.data.data.type;
        this.name = response.data.data.name;

        this.track_type = parseInt(response.data.data.track_type);

        this.edit_name = this.name;
        this.edit_track_type = this.track_type;
        try {
          var start_time = new Date();
          start_time.setTime(response.data.data.start_time);
          this.start_time = start_time;

          this.edit_date = new Date(this.start_time)
            .toISOString()
            .substr(0, 10);
          this.edit_time =
            (new Date(this.start_time).getHours() + "").padStart(2, "0") +
            ":" +
            (new Date(this.start_time).getMinutes() + "").padStart(2, "0");
        } catch (e) {
          this.edit_date = new Date().toISOString().substr(0, 10);
          this.edit_time = "00:00";
        }

        var image_path =
          "https://maps.googleapis.com/maps/api/staticmap?key=" +
          GOOGLE_MAPS_KEY +
          "&size=400x200&path=color:0x000000ff|weight:2";
        var distance;

        this.raw = response.data.data.route;
        switch (this.type) {
          case "tcx":
            parseTCXString(
              response.data.data.route,
              function(status, result) {
                this.track = result;

                distance = result.distance;

                //More metadata
                this.duration = result.duration;

                var splitter = Math.round(
                  this.track.laps[0].track.length / 100
                );
                this.track.laps[0].track.forEach(
                  function(point, index) {
                    this.points.push({
                      lat: point.latitude,
                      lng: point.longitude
                    });

                    if (index % splitter === 0) {
                      image_path +=
                        "|" + point.latitude + "," + point.longitude;
                    }

                    if (
                      index % 20 === 0 &&
                      point.altitude !== null &&
                      point.altitude !== undefined
                    ) {
                      this.elevations.push(Math.round(point.altitude));
                    }
                    if (
                      index % 20 === 0 &&
                      point.hr !== null &&
                      point.hr !== undefined
                    ) {
                      this.heartRate.push(Math.round(point.hr));
                    }
                  }.bind(this)
                );
              }.bind(this)
            );
            break;
          case "gpx":
          default:
            var gpx = new gpxParser();
            gpx.parse(response.data.data.route);
            this.track = gpx.tracks[0];

            distance = gpx.tracks[0].distance.total;

            var splitter = Math.round(this.track.points.length / 100);

            var internal_start_time = undefined, internal_end_time = undefined;
            this.track.points.forEach(
              function(point, index) {
                this.points.push({
                  lat: point.lat,
                  lng: point.lon
                });

                if (index === 0) {
                  internal_start_time = point.time;
                } else if (this.track.points.length - 1 === index) {
                  internal_end_time = point.time;
                }

                if (index % splitter === 0) {
                  image_path += "|" + point.lat + "," + point.lon;
                }

                if (
                  index % 20 === 0 &&
                  point.ele !== null &&
                  point.ele !== undefined
                ) {
                  this.elevations.push(point.ele);
                }

                if (
                  index % 20 === 0 &&
                  point.hr !== null &&
                  point.hr !== undefined
                ) {
                  this.heartRate.push(point.hr);
                }
              }.bind(this)
            );

            if (internal_start_time !== undefined && internal_end_time !== undefined) {
              this.duration = this.moment(internal_end_time).diff(internal_start_time, "seconds"); 
            } else {
              this.duration = gpx.metadata.duration;
            }

            //console.log(response.data.data.route);
            break;
        }
      }

      if (!response.data.data.calc_distance || !response.data.data.image_path) {
        await storage.update("tracks", this.id, {
          thumb_path: image_path,
          calc_distance: distance,
          calc_heartrate: this.heartRateAvg
        });
      }
      this.loaded = true;
    },
    async editTrack(e) {
      e.preventDefault();
      var response = await storage.update("tracks", this.id, {
        name: this.edit_name,
        start_time: +this.moment(this.edit_date + "T" + this.edit_time).tz(
          "Europe/Zurich"
        ),
        track_type: this.edit_track_type
      });
      if (response.isOK) {
        this.name = this.edit_name;
        this.start_time = this.moment(this.edit_date + "T" + this.edit_time)
          .tz("Europe/Zurich")
          .toDate();
        this.track_type = this.edit_track_type;
        this.d_edit = false;
        Notifications.show(this.$t("tracks.notification.track_has_been_saved"));
      } else {
        Notifications.show(response.message);
      }
    },
    async deleteTrack() {
      if (confirm("Do you really want to delete this track?")) {
        var response = await storage.delete("tracks", this.id);
        if (response.isOK) {
          Notifications.show(this.$t("tracks.notification.track_has_been_deleted"));
          this.$router.replace("/tracks");
        } else {
          Notifications.show(response.message);
        }
      }
    },
    download() {
      var link = document.createElement("a");
      link.download = this.name + "." + this.type;
      link.href = this.dataURI;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    getTrackTypeFormatted(number) {
      if (Number.isNaN(number)) {
        return "-";
      }
      var type = track_types.getTrackType(number);
      return (
        "<i class='fad " +
        type.icon +
        "' style='margin-right:5px'></i><span>" +
        this.$t(type.name) +
        "</span>"
      );
    },
    getCenterFromCoordinates(coordinates) {
      var lats = 0,
        lngs = 0;
      coordinates.forEach(function(curr) {
        lats += curr.lat;
        lngs += curr.lng;
      });
      return { lat: lats / coordinates.length, lng: lngs / coordinates.length };
    },
    getZoomLevel(coordinates) {
      var GLOBE_WIDTH = 256;
      var pixelWidth = window.innerWidth / 3;
      var pixelHeight = 300;
      var west = 180,
        east = -180,
        north = 180,
        south = -180;
      coordinates.forEach(function(curr) {
        if (curr.lng < west) {
          west = curr.lng;
        }
        if (curr.lng > east) {
          east = curr.lng;
        }

        if (curr.lat < north) {
          north = curr.lat;
        }
        if (curr.lat > south) {
          south = curr.lat;
        }
      });
      var angle = east - west;
      if (angle < 0) {
        angle += 360;
      }

      var angle2 = south - north;
      if (angle2 < 0) {
        angle2 += 360;
      }

      return (
        Math.round(
          (Math.round(
            Math.log((pixelWidth * 360) / angle / GLOBE_WIDTH) / Math.LN2
          ) +
            Math.round(
              Math.log((pixelHeight * 360) / angle2 / GLOBE_WIDTH) / Math.LN2
            )) /
            2
        ) - 2
      );
    },
    convertDateTime(datetime) {
      if (!(datetime instanceof Date && !isNaN(datetime))) {
        return this.$t("general.no_date_defined");
      }
      var months = [
        this.$t("general.month.january"),
        this.$t("general.month.february"),
        this.$t("general.month.march"),
        this.$t("general.month.april"),
        this.$t("general.month.may"),
        this.$t("general.month.june"),
        this.$t("general.month.july"),
        this.$t("general.month.august"),
        this.$t("general.month.september"),
        this.$t("general.month.october"),
        this.$t("general.month.november"),
        this.$t("general.month.december")
      ];

      var day_of_week = [
        this.$t("general.day_of_week.sunday"),
        this.$t("general.day_of_week.monday"),
        this.$t("general.day_of_week.tuesday"),
        this.$t("general.day_of_week.wednesday"),
        this.$t("general.day_of_week.thursday"),
        this.$t("general.day_of_week.friday"),
        this.$t("general.day_of_week.saturday")
      ];

      return (
        day_of_week[datetime.getDay()] +
        ", " +
        datetime.getDate() +
        ". " +
        months[datetime.getMonth()] +
        " " +
        datetime.getFullYear() +
        " " +
        (datetime.getHours() + "").padStart(2, "0") +
        ":" +
        (datetime.getMinutes() + "").padStart(2, "0")
      );
    }
  }
};
</script>

<style>
.fa-rotate-45 {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}
</style>